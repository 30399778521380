
import { Component, Vue } from 'vue-property-decorator'
import { dayFormat } from '@/utils/formatDate'
import { mapKey } from '@/utils/config'
import { SignInRecordDetail } from '@/types/inspection'
import AMapLoader from '@amap/amap-jsapi-loader'
@Component
export default class SignInRecord extends Vue {
  private searchInfo: { projectName: string; userName: string; dateRange: Array<string> } = {
    projectName: '',
    userName: '',
    dateRange: []
  }

  private loading = false
  private list: Array<SignInRecordDetail> = []
  private signInImg = require('@/assets/img/inspection/icon_map_qiandao.svg')
  private signInImgCursor = require('@/assets/img/inspection/icon_map_qiandao_dianji.svg')
  private map: AMap.Map | undefined = undefined
  private markers: Array<AMap.Marker> = []
  private currentDetail: SignInRecordDetail | null = null
  private page = 1
  private size = 10
  private total = 0

  get projectList () {
    return this.$store.state.projectList
  }

  created (): void {
    this.initDate()
    this.getList()
  }

  // 默认搜索时间为当前月
  initDate () {
    const end = dayFormat(new Date().getTime())
    const start = end.substring(0, end.lastIndexOf('-') + 1) + '01'
    this.searchInfo.dateRange = [start, end]
    console.log(this.searchInfo)
  }

  // 获取列表数据
  getList (): void {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<SignInRecordDetail> }>(this.$apis.inspection.selectWoAutographPage,
      {
        projectName: this.searchInfo.projectName,
        userName: this.searchInfo.userName,
        startTime: this.searchInfo.dateRange && this.searchInfo.dateRange[0] ? this.searchInfo.dateRange[0] : '',
        endTime: this.searchInfo.dateRange && this.searchInfo.dateRange[1] ? this.searchInfo.dateRange[1] : '',
        page: this.page,
        size: this.size
      }).then(res => {
      this.total = res.total || 0
      this.list = res.list || []
      this.loadMap()
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch (): void {
    this.page = 1
    this.getList()
  }

  handleCurrentChange (row?: SignInRecordDetail) {
    if (row) {
      this.setSelect(row)
    }
  }

  loadMap () {
    AMapLoader.load({
      key: mapKey,
      version: '2.0'
    }).then(() => {
      this.$nextTick(() => {
        const map = new AMap.Map('container', {
          zoom: 14
        })
        this.map = map
        // 点击地图关闭抽屉
        this.map.on('click', this.clearSelect)
        this.drawMarker()
      })
    }).catch(e => {
      this.$message({ type: 'error', message: '地图加载失败：' + JSON.stringify(e) })
    })
  }

  // 地图打点
  drawMarker () {
    this.markers = []
    if (this.list && this.list.length > 0) {
      // 循坏将设备marker添加到地图
      let isFirst = true
      this.list.forEach((item: SignInRecordDetail) => {
        if (item.longitude && item.latitude) {
          if (isFirst) {
            this.map && this.map.setCenter([Number(item.longitude), Number(item.latitude)])
            console.log([Number(item.longitude), Number(item.latitude)])
            isFirst = false
          }
          const icon = this.signInImg
          const marker: any = new AMap.Marker({
            icon: new AMap.Icon({
              // 图标的取图地址
              image: icon
            }),
            offset: new AMap.Pixel(-18, -48),
            position: new AMap.LngLat(Number(item.longitude), Number(item.latitude)),
            extData: item
          })
          marker.on('click', this.markerClick)
          this.map && this.map.add(marker)
          this.markers.push(marker)
        }
      })
    }
  }

  // 签到点点击
  markerClick (e: any) {
    const detail = e.target.getExtData() as SignInRecordDetail
    if (this.currentDetail === null || (this.currentDetail && this.currentDetail.id !== detail.id)) {
      if (this.currentDetail && this.currentDetail.id !== detail.id) {
        this.clearSelect()
      }
      this.currentDetail = detail
      // 点击切换大图标
      e.target.setOffset(new AMap.Pixel(-24, -64))
      e.target.setIcon(this.signInImgCursor)
      this.showInfoWindow()
    }
  }

  // 选中当前签到点
  setSelect (detail: SignInRecordDetail) {
    if (this.currentDetail === null || (this.currentDetail && this.currentDetail.id !== detail.id)) {
      if (this.currentDetail && this.currentDetail.id !== detail.id) {
        this.clearSelect()
      }
      this.currentDetail = detail
      // 放大当前选中图标
      if (this.map) {
        const index = this.markers.findIndex((item: any) => item.getExtData().id === detail.id)
        if (index >= 0) {
          const marker = this.markers[index] as AMap.Marker
          marker.setOffset(new AMap.Pixel(-24, -64))
          marker.setIcon(this.signInImgCursor)
          this.map.setCenter(marker.getPosition() as AMap.LngLat)
          this.showInfoWindow()
        }
      }
    }
  }

  // 信息窗口
  showInfoWindow () {
    this.$nextTick(() => {
      const infoWindow = new AMap.InfoWindow({
        content: this.$refs.infoWindow as any,
        isCustom: true,
        autoMove: true,
        closeWhenClickMap: true,
        anchor: 'bottom-center',
        offset: [0, -70] as any
      })
      const detail = this.currentDetail as SignInRecordDetail
      this.map && infoWindow.open(this.map, [Number(detail.longitude), Number(detail.latitude)])
    })
  }

  // 清除选中
  clearSelect () {
    if (this.currentDetail) {
      // 将图标替换
      if (this.map) {
        const id = this.currentDetail.id || ''
        const index = this.map.getAllOverlays('marker').findIndex((item: any) => item.getExtData().id === id)
        if (index >= 0) {
          const marker = this.map.getAllOverlays('marker')[index] as AMap.Marker
          marker.setOffset(new AMap.Pixel(-18, -48))
          marker.setIcon(this.signInImg)
        }
      }
      // 清空
      this.currentDetail = null
    }
  }
}
